//App.js
import React from "react"
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom"
import TopMenu from "./component/TopMenu"
import Pathway from "./component/Pathway"
import About from "./component/About"
import Privacy from "./component/Privacy"
import Terms from "./component/Terms"
import {InterviewProvider} from "./component/InterviewContext"

import "./App.css"
import "./component/pages/Interview.css"

const App = () => {
  return (
    <InterviewProvider>
      <BrowserRouter>
       <Routes>
         <Route path="/" element={<TopMenu />}>
           <Route index              element={<Pathway />} />
           <Route path="/about"      element={<About />} />
           <Route path="/privacy"    element={<Privacy />} />
           <Route path="/terms"      element={<Terms />} />
           <Route path="*"           element={<Navigate to ="/" />}/>
         </Route>
       </Routes>
      </BrowserRouter>
    </InterviewProvider>
   )
}

export default App;
